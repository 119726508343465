import React from 'react'
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'

function Home() {
  return (
    <>
      <SectionOne/>
      <SectionTwo/>
    </>
  )
}

export default Home
