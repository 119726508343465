import React from 'react'
import "./login.css"

const Login = () => {
  return (
    <div>
      <div className="container d-flex justify-content-center align-items-center vh-100  "style={{ backgroundImage:('https://mdbcdn.b-cdn.net/img/new/standard/city/041.webp')
   , height:" 400px"}}>
      <div className="card p-4 shadow-lg" style={{ width: "400px" }}>
        <h2 className="text-center mb-4">Login</h2>
        <form>
          {/* Email Field */}
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Enter your email"
              required
            />
          </div>

          {/* Password Field */}
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Enter your password"
              required
            />
          </div>

          {/* Submit Button */}
          <button type="submit" className="btn btn-primary w-100">
            Login
          </button>

          {/* Forgot Password */}
          <div className="text-center mt-3">
            <a href="#!" className="text-primary">
              Forgot Password?
            </a>
          </div>
        </form>

        {/* Divider */}
        <hr className="my-4" />

        {/* Social Login */}
        <div className="text-center">
          <p>Or login with</p>
          <div className="d-flex justify-content-center gap-3">
            <button className="btn btn-outline-primary">
              <i className="fab fa-facebook"></i> Facebook
            </button>
            <button className="btn btn-outline-danger">
              <i className="fab fa-google"></i> Google
            </button>
          </div>
        </div>
      </div>
    </div>       
    </div>
  )
}

export default Login
