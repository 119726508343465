import React from 'react'

function Admission() {
  return (
    <div>
      login to the page
    </div>
  )
}

export default Admission
