import React from 'react'

const Department = () => {
  return (
    <div>
      hiii log
    </div>
  )
}

export default Department
