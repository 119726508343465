import React from 'react'

const Events = () => {
  return (
    <div>
      element
    </div>
  )
}

export default Events
