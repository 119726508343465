import React from "react";
import Header from "./Component/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Component/Home";
import Footer from "./Component/Footer";
import About from "./Component/About";
import Department from "./Component/Department";
import Admission from "./Component/Admission";
import Contact from "./Component/Contact";
import Login from "./Pages/Login";
import Events from "./Component/Events";

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/department" element={<Department />} />
        <Route path="/admission" element={<Admission />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
