import React from "react";
import './Style.css';
import { PiStudent, PiStudentFill } from "react-icons/pi";
import CountUp from "react-countup";
const SectionTwo = () => {
  return (
    <>
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-4 course_section">
            <div className="row">
              <div className="col-md-5">Workers
                <PiStudentFill style={{fontSize:"50px"}}/>
              </div>
              <div className="col-sm-7">
                <div className="col">Office Staff</div>
                <div className="col"><CountUp end={100} duration={10}/></div>
                <div className="col">delivery Boy</div>
                <div className="col"><CountUp end={10000} duration={10}/></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="col-md-3"></div>
        </div>
      </div>
    </>
  );
};

export default SectionTwo;
