import React from "react";

const SectionOne = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <img src="../image/dd.jpg" style={{ width: "100%" }} />
          </div>
          <div className="col-md-6">
            <h2 style={{color:"skyblue"}}>Astitva Logistic</h2>
            
            The product delivery process is the journey of turning a product
            idea into a finished product, and it involves many steps, including:
            Ideation: Developing the product idea Development: Designing,
            coding, and testing the product Release: Releasing the product to
            the end-user Post-release: Monitoring and optimizing the product,
            including tracking usage data, fixing bugs, and making changes based
            on feedback The product delivery process can be complex and involves
            many stakeholders, including developers, sales team.
          </div>
        </div>
        <h1 style={{color:"skyblue"}}>Astitva Logistic</h1>
      </div>
      <hr/>
    </>
  );
};

export default SectionOne;
