import React from "react";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { PiRoadHorizonFill } from "react-icons/pi";

function Footer() {
  return (
    <>
      <footer class="text-center text-lg-start bg-dark text-white">
        <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div class="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a href="" class="me-4 text-reset">
              <FaInstagram />
            </a>
            <a href="" class="me-4 text-reset">
              <FaFacebook />
            </a>
            <a href="" class="me-4 text-reset">
              <FaTwitter />
            </a>
            <a href="" class="me-4 text-reset">
              <FaGithub />
            </a>
            <a href="" class="me-4 text-reset">
              <FaTelegram />
            </a>
            <a href="" class="me-4 text-reset">
              <SiGmail />
            </a>
          </div>
        </section>

        <section class="">
          <div class="container text-center text-md-start mt-5">
            <div class="row mt-3">
              <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 class="text-uppercase fw-bold mb-4">
                  <i class="fas fa-gem me-3"></i>Astitwa Logistic
                </h6>
                <p>Astitwa Making people successful.   
                Always Live with a Positive Mindset. Every action begins with a thought. When your thoughts are positive, you will naturally feel more motivated to take ... </p>
              </div>
              <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 class="text-uppercase fw-bold mb-4">Products</h6>
                <p>
                  <a href="#!" class="text-reset">
                    Clothes
                  </a>
                </p>
                <p>
                  <a href="#!" class="text-reset">
                    Electronics
                  </a>
                </p>
                <p>
                  <a href="#!" class="text-reset">
                    Wood design toy
                  </a>
                </p>
                <p>
                  <a href="#!" class="text-reset">
                    Vehicles.etc
                  </a>
                </p>
              </div>
              <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 class="text-uppercase fw-bold mb-4">Account</h6>
                <p>
                  <a href="#!" class="text-reset">
                    Your order
                  </a>
                </p>
                <p>
                  <a href="#!" class="text-reset">
                    Track order
                  </a>
                </p>
                <p>
                  <a href="#!" class="text-reset">
                    Cart
                  </a>
                </p>
                <p>
                  <a href="#!" class="text-reset">
                    Settings
                  </a>
                </p>
                <p>
                  <a href="#!" class="text-reset">
                    Help
                  </a>
                </p>
              </div>
              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 class="text-uppercase fw-bold mb-4">
                  Contact:- +91 8302335366
                </h6>
                <p>
                  <PiRoadHorizonFill /> Jaipur, pincode:-302021, Rajsthan
                </p>
                <p>
                  <MdEmail />
                  astitwalogistic@gmail.com
                </p>
                <p>
                  <FaPhoneAlt />+ 91 95 09 57 14 65
                </p>
                <p>
                  <FaPhoneAlt /> + 91 63 67 29 23 93
                </p>
              </div>
            </div>
          </div>
        </section>

        <div
          class="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2024 SmartWork:
          <a class="text-reset fw-bold" href="https://mdbootstrap.com/">
            Er.NareshKumawat.com
          </a>
        </div>
      </footer>
    </>
  );
}

export default Footer;
